// 1. Site Footer
.snFooterContainer{
  width:100%;
  border-top: 10px solid $blue;
  background-color: $light-grey;
  bottom: 0px;
  position: relative;
}
#siteFooter {
  max-width:$maxWidth;
  margin:0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
  min-height: 300px;
  font-family: $fontOne;
  text-transform: uppercase;
  font-size: em(18);
  line-height: 1;
  font-weight: 600;
  color: $blue;

  ul{
    list-style: none;
  }
  #poweredByNGIN{ height: 0;}
}

.footer-site-logo,
.footer-site-links,
.footer-partners-social,
.footer-orgs{
  margin: 15px 15px;
}

.footer-site-logo{
  order:1;
  position: relative;
  width: 100%;
  width: 154px;
  flex-shrink: 0;
  .sn-site-logo-background{
    display: block;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    padding-bottom: 124.67%;
  }
}

.footer-site-links{
  order: 2;
  column-count: 2;
  line-height: 1.4;
  li{
    break-inside: avoid;
    // &:empty{ display: none;}
    a{display: inline-block;}
  }
}
@media screen and(max-width: 400px){
  .footer-site-links{
    column-count: 1;
    width: 100%;
    flex-basis: 100%;
    text-align: center;
  }
}

.footer-partners-social{
  order: 3;
  text-align: center;
  .proud-partners{
    display: inline-block;
    background-image: url(../images/partners.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 81px;
    height: 41px;
    margin-bottom: 10px;
  }
  .sn-social-media-icon{
    font-size: 35px;
    background-color: $blue;
    color: #FFF;
    transition: background-color $transition-standard, color $transition-standard;
    &:hover{
      background-color: #FFF !important;
      color: $blue;
    }
    &.icon-facebook:before{content: "\f230";}
    &.icon-instagram:before{content: "\f16d";}
    &.icon-youtube:before{content: "\f16a";}
  }
}

.footer-orgs{
  order: 4;
  max-width: 210px;
  flex-basis: 210px;
  font-size:  em(14);
  text-align: center;
  line-height: 1.4;
  font-weight: 500;
  .usa-hockey-logo{
    display: inline-block;
    margin-bottom: 35px;
    width: 200px;
    height: 99px;
    background-image: url(../images/usa-hockey-logo.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
