#siteContainer {
  &, #siteContainer.container-fluid {
    padding: 0;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;
  }

  // @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    width: 100%;
    height: auto;
    padding: 15px;
    font-size: em(42);
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    &:before, &:after{
      content:"";
      display:block;
      width: 100px;
      // flex-basis: 100px;
      border-bottom: 1px solid $red;
      // flex: 1 1 100px;
    }
    span{
      margin: 0 3vw;
      flex-wrap: wrap;
      text-align: center;
    }
  }

  #yieldContent.row {
    margin: 0px;
    width: 1200px;
  }

  #panelOne {
    padding: 0 15px;
    border-right: 1px solid $light-grey;
    margin-bottom: 15px;

    a {
      line-height: 30px;
      width: 100%;
      position: relative;
      display: inline-block;
    }
    .selected {
      font-weight: 600;
      width: 100%
    }
    .selectedChild {
      top: 0px;
      padding-left: 10px;
    }

  }

  .yieldPageContent { padding:0; }
}


@media only screen and (max-width: 1260px) {
  #siteContainer {
    #yieldContent.row {
      margin: 0px;
      width: 100%;
    }
  }

}
