// $site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height + 3;
// $site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;

$site-logo-width: 100px;
$site-logo-height: 100px;
// $site-logo-size-fixed: ;

#sn-site-logo {
  // z-index: 1000;
  transition: all $transition-linear;
  align-self: flex-start;
  flex-shrink: 0;
  .sn-site-logo-wrap {
    display: block;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: block;
    width: $site-logo-width;
    height: $site-logo-height;
    margin-top: 12px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-background-size: contain;
  }
}
