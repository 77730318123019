// Utilities
$sn-pageManager-width: 60px;
%standard-width{
  max-width: $maxWidth;
  width: 100%;
  margin: 0 auto;
}


ul#Past_Inductees {
    display: none !important;
}


%full-width{
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .page-manager-visible & {
    @media screen and (min-width: 768px){
      width: calc(100vw - #{$sn-pageManager-width});
      // margin-left: -30px;
    }
  }
}

%full-width-image-background{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-width{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // margin-right: 0;
  &.layout-100{
    padding-left: 0;
    .column{ padding-left: 0; padding-right: 0;}
  }
}
.full-width-container{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // @extend %full-width-image-background;
  // margin-right: 0;
  .fw-content{
    @extend %standard-width;
    // padding: 0 $content-container-padding;
  }

  // &.oneColumn{
  //   .pageElement{
  //     margin-right: 0;
  //   }
  // }
}
.background-image{
  .user_mode &{ display: none; }
  .edit_mode &{
    &:before{
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }
    img{
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    }
  }
}
.background-cover-img{
  @extend %full-width-image-background;
}
.padding{
  padding-bottom: 33px;
  padding-top: 33px;
  &-top{padding-top: 33px;}
  &-bottom{padding-bottom: 33px;}
}
.gray{
  background-color: $light-grey;
}
.element-header{
  margin: 15px 0 15px 0;
  .textBlockElement{margin-bottom: 0;}
  .textBlockElement > h3{
    @include special-header;
    // margin-bottom: 25px;
  }
  &:last-child{
    .textBlockElement > h3{ margin-bottom: 0;}
  }
}

#topNav #page_node_2915434 .dropdown-open {
    display: none !important;
}

// Page Adjustments
.home #siteContainer{
  padding-top: 0;
}
.newsPage #siteContainer {
    padding-top: 15px;
}
.edit_mode #siteContainer{
  margin-top: 50px;
}
.has-main-nav #sport-header{
  margin-top: 30px;
}

// .home .pageEl:last-child .pageElement{
//   margin-bottom: 0;
// }


// Date highlight
.date-highlight{
  background-color: $red;
  .user_mode &{ box-shadow: 0 -5px 20px 5px #505050; }

  .textBlockElement{ margin-bottom: 0; }
  .text p{
    color: #FFF;
    text-transform: uppercase;
    font-family: $fontOne;
    line-height: 1.2;
    padding: 10px;
  }
}


// Full width image

.hero{
  &.background-cover-img{
    // min-height: 500px;
    @include aspect-ratio(1200,300);
  }
  // .fw-content{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
  // }
}
.hero-text{
  max-width:650px;
  width: 100%;
  padding: 25px 25px;
  .textBlockElement{
    margin-bottom: 0;
    border-bottom: 10px solid $blue;
  }
  h3{
    background-color: rgba(#FFF, .9);
    font-size: em(42);
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0;
    padding: 30px 15px;
  }
}

// boxscore
.home [id^="widget_game_highlight_widget_"] {
  .boxscore{
    display: none;
  }
  .game-header{
    background-color: #FFF;
  }
  .game-header ~ .boxscore-inner-container{
    margin-top:0;
  }
  .location{
    display: none;
  }
}


// News Aggregators
.home .newsAggregatorElement{
  .condensed + .extendedOptions{
    display: none;
  }
}

// Responsive iframe video
.user_mode .intrinsic-ratio .codeElement {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 70%; // videos height/width — 350/500 = .70 or 70%
  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.no-controls .video_aspect_ratio{
  padding-bottom: 0;
}

// Banner Ad

.home {
  .adElement {
    margin-top: 15px;
  }
}


// Sponsors
.sponsors{
    padding-left: 15px;
    padding-right: 15px;
  .column{
    display: flex !important;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-top:0
  }
  .pageEl{
    margin: 15px 10px;
  }
  .heroPhotoElement{
    max-width: 200px;
    margin-bottom: 0;
    img{
      max-height: 150px;
      &[src$=".svg"]{
        height: 70px;
      }
      border: none;
    }
  }
  //edit mode
  // .drag{
  //   display: inline-block;
  // }
  // .elementBar .elementTitle{ width: auto;}
  .addPageElementTop{
    width: 100%;
    flex-basis: 100%;
  }
  .columnBounds{
    display: flex;
    flex-flow: row wrap;
  }
  .drag{
    width: 25%;
    flex-basis: 25%;
  }
}

// Twitter element

.home iframe#twitter-widget-0 {
  border: 1px solid $light-grey !important;
  // margin-top: -12px !important;
  height: 446px !important;
}

// Video element

.videoElement {
  // margin-top: 28px !important;
}

// Custom Position of Home Page Site Background
.site-background.customSiteBackground {
  background-attachment: scroll;
  background-position-y: 475px;
}


.nav-fixed {
  .site-background.customSiteBackground {
    background-attachment: scroll;
    background-position-y: 495px;
  }
}


.home{
  .custom-cta {
    margin-left: 0;
    margin-right: 0;
  }
  #callToAction-39413, #callToAction-39414, #callToAction-39415{
    margin-bottom: 0;
  }
}

// Custom 3 up news agg on Home Page

.layoutContainer.row.layout-100.custom-news-agg-container {
  margin-top:20px;
  margin-bottom: 0px;
}


.item.expanded.customNewsAgg {
    width: 32.33%;
    float: left;
    min-height: 375px;
    height: 425px;
    border: none !important;
    overflow-x: hidden;
}

//Hide News Agg rsslink on Home
.home a.rssLink {
  display: none;
}

.home .newsAggregatorElement .dateAuthor {
  display: none;
}

// Bottom border for twitter unit
.home .pageElement.codeElement {
    height: 435px;
    border-bottom: 1px solid $light-grey;
}

// Custom Padding for Home CTA
.no-cta-padding {
  padding-left: 0px;
  padding-right: 0px;
}

// Custom News Styles
.home {
  .custom-expanded-news .expanded .newsItemHeader > a img{
    width: 378px;
    height: 250px;
    overflow-x: hidden;
  }
  .newsAggregatorElement .articleHasImage .newsItemHeader > a {
    top: -30px;
    padding: 0px;
  }
  .even.item.expanded.customNewsAgg {
    margin: 0 1.5% 0 1.5%;
  }
  .expanded .newsItemHeader h2 {
    top: 235px;
    position: absolute;
    width: 100%;
    left: 0px;
    height: auto;
    font-weight: 600;
  }
  .condensed {
    margin-bottom: 10px;
    border: 1px solid $light-grey;
  }
  .expanded .newsItemElement h4 {
    top: 305px;
    position: relative;
    width: 100%;
    left: 0;
    height: auto;
    padding: 0px;
    text-transform: none;
    font-size: 16px;
    color: #171717;
  }
  .newsText p {
    display: none !important;
  }
}

.customNewsAgg-editMode {
  width: 100.3%;
}

.mobileOnly {
  display: none;
}

@media only screen and (max-width: (1260px)){
.home {
  .newsSlideShow-headline{
    left: 0px;
  }

}
}

@media only screen and (max-width: (820px)){
.home {
  .desktopOnly{
    display: none;
  }
  .mobileOnly {
    display: block !important;
  }
  a.icon.goToLink {
    margin-top: 10px;
    margin-bottom: 10px;
  }

}
}

@media only screen and (max-width: (768px)){
  .home {
    overflow-x: hidden;

    .layoutContainer.row.layout-100.full-width {
    width: 100.5%;
    left: 49.7%;
    }

    .videoElement {
      // margin-top: 20px;
      margin-bottom: 20px;
    }

    .custom-cta {
      margin-bottom: 15px;
    }
    .no-cta-padding {
      padding-left: 15px;
      padding-right: 15px;
    }

    .newsSlideShow-container {
      height: 375px !important;
    }

    .newsSlideShow-headline {
      bottom: 110px;
      h1 {
      height: 80px;
        &:before{
          padding-top: 31px;
          height: 61%;
        }
        a {
        font-size: 16px;
        padding:0;
        }
      }
    }

    a.icon.goToLink {
      margin-bottom: 10px;
    }



  }


}

//Tablet
@media only screen and (min-width: 768px) {
   .home {
     .newsSlideShow-headline {
       h1 {
         a {
         font-size: 24px;
         }
       }
     }
   }
 }
